<template>
    <b-card class="max-w-xl">
      <SoilIdentification/>
    </b-card>
</template>

<script>
import SoilIdentification from '@/components/vgarden/evaluations/SoilIdentification.vue'
import {BCard} from "bootstrap-vue";

export default {
  name: 'SoilIdentificationPage',
  components: {
    SoilIdentification,
    BCard,
  }
}
</script>
